<template>
  <v-container
    id="user-profile"
    fluid
    tag="section"
  >
    <v-row justify="center">
      <v-col
        cols="12"
        md="8"
      >
        <base-material-card>
          <template v-slot:heading>
            <div class="display-2 font-weight-light">
              Create New Users
            </div>

            <div class="subtitle-1 font-weight-light">
              Complete your profile
            </div>
          </template>

          <v-form v-model="isValid">
            <template>
              <validation-observer ref="observer">
                <form>
                  <validation-provider
                    v-slot="{ errors }"
                    name="Name"
                  >
                    <v-text-field
                      v-model="name"
                      placeholder="Masukan Area"
                      :error-messages="errors"
                      label="Region"
                      required
                    />
                  </validation-provider>
                  <validation-provider
                    v-slot="{ errors }"
                    name="Status"
                    rules="required|"
                  >
                    <v-select
                      v-model="status"
                      :items="status_items"
                      :error-messages="errors"
                      label="Status"
                      required
                      item-text="status"
                      item-value="id"
                    />
                  </validation-provider>

                  <v-btn
                    class="mr-4"
                    type="submit"
                    :disabled="!isValid"
                    @click.prevent="storeArea"
                  >
                    submit
                  </v-btn>
                </form>
              </validation-observer>
            </template>
          </v-form>
        </base-material-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
  import axios from 'axios'
  axios.defaults.baseURL = 'https://nc.notiva.net'
  axios.defaults.headers.common.Authorization =
    'Bearer ' + localStorage.getItem('access_token')
  export default {
    name: 'AnggotaCreate',
    data () {
      return {
        region: {
          data: [],
          meta: {},
        },
        status_items: ['active', 'inactive'],
        name: '',
        status: null,
        isValid: false,
        emailRules: [value => value !== '' || 'Form Harus Di isi '],
      }
    },
    // mounted () {
    //   this.getRegion()
    // },
    methods: {
      storeArea () {
        axios
          .post('/v1/region', {
            name: this.name,
            status: this.status,
          })
          .then(response => {
            if (response.data.meta.status) {
              this.$router.push({ path: '/region' })
              this.$toast.success('Berhasil Di Buat', {
                type: 'success',
                position: 'top-right',
                duration: 3000,
                dismissible: true,
              })
            } else {
              console.log(response.data.meta.message, 'hai')
            }
          })
      },
      setRegion (data) {
        this.region = data
      },
    //   getRegion () {
    //     axios.get('/v1/region?entities=community&sort=-id').then(response => {
    //       this.setRegion(response.data)
    //     })
    //   },
    },
  }
</script>

<style></style>
